import { html } from "haunted";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";

import { getParsedProperty } from "Services/common";
import { SPAIN_CULTURE_CODE, USA_CULTURE_CODE } from "Services/constants";

export const useShadowDOM = false;
export const name = "ac-route-settings";

interface CountrySetting {
	index: number;
	culture: string;
	defaultOriginCode: string;
	defaultDestinationCode: string;
}

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
	model: string;
}

export interface Props {
	model: CountrySetting[];
}

const countryMap = new Map<string, string>([
	["es-cl", "Chile"],
	["es-ar", "Argentina"],
	["pt-br", "Brazil"],
	["es-co", "Colombia"],
	["es-ec", "Ecuador"],
	["en-us", "English"],
	["en-es", "Otro (ES)"],
	["es-pe", "Peru"],
	["es-py", "Paraguay"],
	["es-uy", "Uruguay"],
]);

const culturesWithoutPossibleCountries = [USA_CULTURE_CODE, SPAIN_CULTURE_CODE];

export const Component: HauntedFunc<Props> = (host) => {
	const parseModel = () => {
		const hostModel =
			typeof host.model === "string" ? getParsedProperty<DefaultRouteCountrySetting[]>(host.model) : undefined;

		return Array.from(countryMap.keys()).map((culture, i): CountrySetting => {
			const setting = hostModel?.find((s) => s.Culture.toLowerCase() === culture.toLowerCase());

			return {
				index: i,
				culture,
				defaultOriginCode: setting?.DefaultOriginCountryCode || "",
				defaultDestinationCode: setting?.DefaultDestinationCountryCode || "",
			};
		});
	};

	const props: Props = {
		model: parseModel(),
	};

	// HELPERS

	// EVENT HANDLERS

	const handleSave = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		(document.getElementById("actionType") as HTMLInputElement).value = "save_dynamic_settings";
		(document.getElementById("admin_form") as HTMLFormElement).submit();
	};

	// COMPONENT

	// TEMPLATES

	const optionTemplate = (cultureCode: string, countryName: string, selectedValue: string) =>
		html`<option
			value=${cultureCode.substring(3)}
			?selected=${selectedValue && cultureCode.includes(selectedValue)}
		>
			${countryName}
		</option>`;

	const optionsTemplate = (selectedValue: string) =>
		html`<option value=""></option>
			${Array.from(countryMap.entries()).map(([cultureCode, countryName]) =>
				!culturesWithoutPossibleCountries.includes(cultureCode.toLowerCase())
					? optionTemplate(cultureCode, countryName, selectedValue)
					: ""
			)}`;

	const rowTemplate = ([cultureCode, countryName]: [string, string]) => {
		const setting = props.model.find((s) => s.culture.toLowerCase() === cultureCode.toLowerCase());

		return html`
			<div class="row mb-4">
				<div class="col-xs-1 col-sm-1-3">
					<div class="dg-admin-select text-right">${countryName}</div>
					<input
						type="hidden"
						name=${`DefaultRouteCountrySettings[${setting.index}].Culture`}
						value=${cultureCode}
					/>
				</div>
				<div class="col-xs-1 col-sm-1-3">
					<select
						class="dg-admin-select"
						name=${`DefaultRouteCountrySettings[${setting.index}].DefaultOriginCountryCode`}
					>
						${optionsTemplate(setting.defaultOriginCode)}
					</select>
				</div>
				<div class="col-xs-1 col-sm-1-3">
					<select
						class="dg-admin-select mb-4 sm:mb-0"
						name=${`DefaultRouteCountrySettings[${setting.index}].DefaultDestinationCountryCode`}
					>
						${optionsTemplate(setting.defaultDestinationCode)}
					</select>
				</div>
			</div>
		`;
	};

	const submitButtonTemplate = () => html`<div class="row">
		<div class="col-xs-1 col-md-4-5">
			<div class="sj-save-warning">
				* After saving, a page refresh (F5) may be necessary to properly reflect the saved state.
			</div>
		</div>
		<div class="col-xs-1 col-sm-1-5 main-buttons">
			<button type="submit" value="Save" class="btn-primary push-down" @click=${handleSave}>Save</button>
		</div>
	</div>`;

	return html`
		<div class="row mb-4">
			<div class="col-xs-1 col-sm-1-3"></div>
			<div class="col-xs-1 col-sm-1-3">Default Origin Country</div>
			<div class="col-xs-1 col-sm-1-3 mb-4 sm:mb-0">Default Destination Country</div>
		</div>
		${Array.from(countryMap.entries()).map(rowTemplate)} ${submitButtonTemplate()}
	`;
};
