import { html, useState } from "haunted";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { getParsedProperty } from "Services/common";
import { COUNTRY_MAP } from "Services/constants";

export const useShadowDOM = false;
export const name = "ac-culture-name-settings";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
	model: string;
}

interface CultureNameSetting {
	index: number;
	culture: string;
	es: string;
	en: string;
	pt: string;
}

export interface Props {
	model: CultureNameSetting[];
}

export const Component: HauntedFunc<Props> = (host) => {
	const parseModel = () => {
		const hostModel =
			typeof host.model === "string" ? getParsedProperty<DynamicCultureNameSetting[]>(host.model) : [];

		return Array.from(COUNTRY_MAP.keys()).map((culture, index): CultureNameSetting => {
			const setting = hostModel?.find((s) => s.Culture.toLowerCase() === culture.toLowerCase());

			return {
				index,
				culture,
				es: setting?.Es ?? COUNTRY_MAP.get(culture),
				en: setting?.En ?? COUNTRY_MAP.get(culture),
				pt: setting?.Pt ?? COUNTRY_MAP.get(culture),
			};
		});
	};

	// HELPER FUNCTIONS

	// EVENT HANDLERS

	const handleInputChange = (e: KeyboardEvent, index: number, key: keyof CultureNameSetting) => {
		e.preventDefault();
		e.stopPropagation();

		const value = (e.target as HTMLInputElement).value;

		setCultureNameSettings((prevSettings) =>
			prevSettings.map(
				(setting): CultureNameSetting => (setting.index === index ? { ...setting, [key]: value } : setting)
			)
		);
	};

	const handleSave = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		(document.getElementById("actionType") as HTMLInputElement).value = "save_dynamic_settings";
		(document.getElementById("admin_form") as HTMLFormElement).submit();
	};

	// COMPONENT

	const [cultureNameSettings, setCultureNameSettings] = useState<CultureNameSetting[]>(parseModel());

	// TEMPLATES

	const rowTemplate = (setting: CultureNameSetting) => {
		const getInputId = (key: keyof CultureNameSetting) =>
			`culture-name-settings-${setting.culture}-culture-input-${key}`;

		return html`
			<div class="row">
				<div class="col-xs-1 col-sm-1-4">
					<label for=${getInputId("culture")}><br />Culture</label>
					<input
						name=${`DynamicCultureNameSettings[${setting.index}].Culture`}
						id=${getInputId("culture")}
						value=${setting.culture}
						disable
						readonly
					/>
				</div>
				<div class="col-xs-1 col-md-1-4">
					<label for=${getInputId("es")}><br />ES</label>
					<input
						name=${`DynamicCultureNameSettings[${setting.index}].Es`}
						id=${getInputId("es")}
						value=${setting.es}
						@input=${(e: KeyboardEvent) => handleInputChange(e, setting.index, "es")}
					/>
				</div>
				<div class="col-xs-1 col-md-1-4">
					<label for=${getInputId("en")}><br />EN</label>
					<input
						name=${`DynamicCultureNameSettings[${setting.index}].En`}
						id=${getInputId("en")}
						value=${setting.en}
						@input=${(e: KeyboardEvent) => handleInputChange(e, setting.index, "en")}
					/>
				</div>
				<div class="col-xs-1 col-md-1-4">
					<label for=${getInputId("pt")}><br />PT</label>
					<input
						name=${`DynamicCultureNameSettings[${setting.index}].Pt`}
						id=${getInputId("pt")}
						value=${setting.pt}
						@input=${(e: KeyboardEvent) => handleInputChange(e, setting.index, "pt")}
					/>
				</div>
			</div>
		`;
	};

	const submitButtonTemplate = () => html`
		<div class="row">
			<div class="col-xs-1 col-md-4-5">
				<div class="sj-save-warning">
					* After saving, a page refresh (F5) may be necessary to properly reflect the saved state.
				</div>
			</div>
			<div class="col-xs-1 col-sm-1-5 main-buttons">
				<button type="submit" value="Save" class="btn-primary push-down" @click=${handleSave}>Save</button>
			</div>
		</div>
	`;

	return html`${cultureNameSettings.map(rowTemplate)}${submitButtonTemplate()}`;
};
